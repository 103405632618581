<template>
  <div class="button-group">
    <img :src="enterIndexImage" @click="handleEnterIndexClick" class="button" />
    <img src="/button_app-download.png" @click="handleAppDownloadClick" class="button" />
    <img src="/button_universe-browser.png" @click="handleUniverseBrowserClick" class="button" />
    <img src="/button_online-service.png" @click="handleOnlineServiceClick" class="button" />
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      enterIndexImage: '/button_enter-index.png'
    };
  },
  methods: {
    handleEnterIndexClick() {
      this.enterIndexImage = '/button_enter-index_click.png';
      this.$emit('openPopup');
    },
    handleAppDownloadClick() {
      const isAndroid = /Android/i.test(navigator.userAgent);
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      let url;
      if (isAndroid) {
        url = this.config.VUE_APP_ANDROID_DOWNLOAD_URL;
      } else if (isIOS) {
        url = this.config.VUE_APP_IOS_DOWNLOAD_URL;
      } else {
        url = this.config.VUE_APP_ANDROID_DOWNLOAD_URL;// 默认下载地址，可以是一个通用的地址
      }
      console.log('App Download URL:', url); // 打印环境变量
      window.location.href = url;
    },
    handleUniverseBrowserClick() {
      const url = this.config.VUE_APP_UNIVERSE_BROWSER_URL;
      console.log('Universe Browser URL:', url); // 打印环境变量
      window.location.href = url;
    },
    handleOnlineServiceClick() {
      const url = this.config.VUE_APP_ONLINE_SERVICE_URL;
      console.log('Online Service URL:', url); // 打印环境变量
      window.location.href = url;
    }
  },
  mounted() {
    console.log('Environment Variables:', process.env); // 打印所有环境变量
  }
};
</script>

<style>
.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 20px;
}

.button {
  width: 30%;
  max-width: 463px;
  height: auto;
  aspect-ratio: 463 / 137;
  cursor: pointer;
  flex: 1 1 calc(50% - 20px); /* Adjust to ensure two buttons per row */
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .button {
    width: calc(50% - 10px); /* Ensure two buttons per row on smaller screens */
    max-width: none;
  }
}
</style>
