<template>
  <div class="popup-overlay" @click="closePopup">
    <div class="popup-content" @click.stop>
      <div class="popup-list">
        <div class="popup-item" v-for="domain in domains" :key="domain.name">
          <div class="domain-info">
            <span class="domain-name">{{ domain.name }}</span>
            <span class="divider"></span>
            <span :class="['domain-latency', { high: domain.latency > 100 || domain.latency === '--' }]" v-if="domain.latency !== '检测中'">{{ domain.latency }}ms</span>
            <span class="domain-latency" v-else>{{ domain.latency }}</span>
          </div>
          <button class="enter-button" @click="openDomain(domain.name)">立即进入</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    domains: {
      type: Array,
      required: true
    }
  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },
    async checkLatency() {
      for (let domain of this.domains) {
        const startTime = Date.now();
        try {
          await fetch(domain.name);
          const latency = Date.now() - startTime;
          domain.latency = latency;
        } catch (error) {
          domain.latency = '--';
        }
      }
    },
    openDomain(url) {
      window.open('https://' + url, '_blank');
    }
  },
  mounted() {
    this.checkLatency();
  }
};
</script>

<style>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  border-radius: 20px;
  width: calc(100% - 40px);
  max-width: 500px;
  height: 250px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  top: -5px; /* Adjust based on your requirements */
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup-list {
  height: calc(100% - 0px);
  overflow-y: auto;
}
.popup-list::-webkit-scrollbar {
  display: none; /* Hide scroll bar for WebKit browsers */
}

.popup-list {
  -ms-overflow-style: none; /* Hide scroll bar for IE and Edge */
  scrollbar-width: none; /* Hide scroll bar for Firefox */
}
.popup-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 2px;
  border-radius: 5px;
}

.domain-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-right: 10px;
  padding: 5px;
  background-color: #ededed;
  border-radius: 20px;
  border: 1px solid #cdcdcd;
  overflow: hidden;
}

.domain-name {
  flex: 1;
  text-align: left;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.domain-latency {
  text-align: right;
  padding: 0 5px;
}

.domain-latency.high {
  color: #f13f31;
}

.domain-latency:not(.high) {
  color: #2fa95b;
}

.divider {
  width: 1px;
  height: 100%;
  background-color: #cdcdcd;
  margin: 0 10px;
}

.enter-button {
  background-color: #18191e;
  color: #fff;
  border: none;
  border-radius: 20px; /* Make the button circular */
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
}

.enter-button:hover {
  background-color: #0056b3;
}
</style>
